<h1>Eire Ford</h1>

<h2>Full Stack Software Engineer</h2>
<h3>Pursuing a lifelong love for building solutions with information technology.  </h3>
<p>
  Full Stack developer with a decade of web development experience with recent coursework<br>
  in Machine Learning and Data Engineering.
</p>
<p>
  Skilled in Java, JavaScript, Typescript, Python, AWS, Google Cloud, Angular, TensorFlow,<br>
  Agile methodology, CI/CD, test automation, and infrastructure as code.
</p>
<p>
  <a href="https://github.com/eireford">github.com/eireford</a><br>
  <a href="https://linkedin.com/in/eireford">linkedin.com/in/eireford</a><br>
  <a href="assets/Eire_Ford_Software_Engineer_2021.pdf">resume</a><br>
</p>
<address>
  eireford@gmail.com<br>
  541.870.9895
</address>
